#app {
  height: 100%;
}

.btn-grid-plus,
.btn-grid-remove {
  margin-top:32px
}

#btn-minus {
  max-width: 55px;
}

#btn-plus {
  max-width: 1%;
}

.container-grid {
  display: flex;
}

.error-input {
  display: none;
  color: red;
  font-size: 16px;
}

.error-input.show {
  display: block;
}

.container-files img {
  max-height: 200px;
  max-width: 200px;
}

.container-files.list .col:first-child {
  min-width: 215px;
}

.container-files.list > .col {
  padding: 0;
}
